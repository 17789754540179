import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import Helmet from "react-helmet";

import FilterBar from "../../components/browser/filter-bar";
import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import PerformancesList from "../../components/performances/performances-list";

class PerformancesForVenuePage extends React.Component {
  constructor({ data, pageContext }) {
    super();

    this.data = data;
    this.pageContext = pageContext;
    this.venueName = pageContext.venueName;
  }

  render() {
    const performances = (this.data.allPerformancesJson) ?
                          this.data.allPerformancesJson : null;
    
    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{`${this.venueName} – Year`}</title>
          </Helmet>

          <PageSection background="dark-accent">
            <h1 className="page-title">Performances by {this.venueName}</h1>
          </PageSection>

          {(performances) ? (
            <PageSection topPadding="md">
              <PerformancesList performances={performances} 
                                artists={this.data.allArtistsJson}
                                venues={this.data.allVenuesJson}
                                grouping='year' />
              <FilterBar filterSet="performances" currentFilter="venue" />
            </PageSection>
          ) : null}
        </PageContainer>
      </Layout>
    )
  }
}

export default PerformancesForVenuePage;

export const query = graphql`
  query PerformancesForVenueQuery($venueReference: String!) {
    allPerformancesJson(
      filter: {venue: {eq: $venueReference}, reviewed: {eq: true}},
      sort: {fields: [dates], order: ASC}) {
      edges {
        node {
          dates
          artists
          description
          venue
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`;
